.attribute {
  display: inline-block;
  margin-bottom: 5px;
}
.card {
  box-shadow: 3px 6px 12px 2px rgba(0,0,0,0.1);
  &:hover {
    box-shadow: 3px 8px 14px 4px rgba(0,0,0,0.15);
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
}

.button{
  display: block;
  width: 100%;
  margin: 5px 0;
  
  svg {
    width: 22px;
    height: 22px;
    margin: 0 3px -3px 0;
  }
}

.alerts {
  padding-bottom: 10px;
  & > div {
    margin: 5px 0;
  }
}

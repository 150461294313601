.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: #000d;
}
.container {
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 90%;
  min-width: 300px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  padding: 0 20px;
  font-size: 30px;
}
.content {
  padding: 20px;
  padding-top: 10px;
}

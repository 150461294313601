.mintSection {
  text-align: center;
  margin: 0 auto;

  & > * {
    margin: 5px;
  }
}

.connectButton {
  text-align: center;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ==========================================================================
  Global overrides
========================================================================== */

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}
a:-webkit-any-link:active {
  color: auto;
}

.site-layout-wrapper {
  padding: 0 50px;
}
.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
.ant-layout-footer {
  text-align: center;
}

.icon-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  display: inline-block;
  &.icon-small {
    width: 14px;
    height: 14px;
  }
}
[data-theme='dark'] {
  .site-layout-content {
    background: #141414;
  }
}
